// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intent-js": () => import("./../../../src/pages/intent.js" /* webpackChunkName: "component---src-pages-intent-js" */),
  "component---src-pages-ivan-js": () => import("./../../../src/pages/ivan.js" /* webpackChunkName: "component---src-pages-ivan-js" */),
  "component---src-pages-olivia-js": () => import("./../../../src/pages/olivia.js" /* webpackChunkName: "component---src-pages-olivia-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-since-js": () => import("./../../../src/pages/since.js" /* webpackChunkName: "component---src-pages-since-js" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */),
  "component---src-pages-year-ten-js": () => import("./../../../src/pages/year-ten.js" /* webpackChunkName: "component---src-pages-year-ten-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-attending-js": () => import("./../../../src/templates/attending.js" /* webpackChunkName: "component---src-templates-attending-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

